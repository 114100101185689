import React from 'react'
import { Pgraph } from '../Pgrah'
import { Headline } from '../Headline'
import { Button } from '../Button'
import { URLS } from '../../config'
import { useRouter } from 'next/router'
import Image from 'next/image'

function ToyBoxMolecule() {
  const router = useRouter()
  return (
    <section className="bg-snow-white md:bg-heavy-cream relative z-0">
      <img
        src="/images/toybox/Toy-Box-Wave-Mobile.svg"
        alt=""
        className="absolute w-full h-auto z-[1] -top-1 md:hidden"
      />
      <img
        src="/images/toybox/Toy-Box-Wave-Tablet.svg"
        alt=""
        className="absolute w-full h-auto z-[1] -top-1 hidden md:block 2xl:hidden"
      />
      <img
        src="/images/toybox/Toy-Box-Wave-Desktop.svg"
        alt=""
        className="absolute w-full h-auto z-[1] -top-1 hidden 2xl:block"
      />

      {/* Wave spacer */}
      <div className="bg-heavy-cream w-full md:h-[60px] lg:h-[65px] xl:h-[85px] 2xl:h-[125px]" />

      <div className="max-w-4xl mx-auto">
        <div className="md:flex md:justify-center md:items-center">
          <div className="m-auto md:w-1/2">
            <img
              src="/images/toybox/toybox.jpg"
              alt="ToyBox"
              height="1100"
              width="958"
              className="z-0"
            />
          </div>
          <div className="md:w-1/2 pt-4 pb-6 md:pr-4 md:mr-6 md:ml-[75px] relative">
            <div className="text-center">
              <Pgraph
                variant="l-14"
                className="text-neutral-6 md:text-right md:text-16px md:leading-18"
              >
                SWAP AS THEY GROW
              </Pgraph>

              <div className="font-recoleta font-medium mt-3 text-neutral-6 md:text-right text-28px leading-34 md:text-32px md:leading-38 lg:text-42px lg:leading-50">
                Introducing Loop Toy Boxes
              </div>
            </div>
            <div className="mt-3">
              <Pgraph
                variant="p-14"
                className="text-neutral-6 px-4 md:px-0 max-w-xs mx-auto text-center xs:leading-18 md:text-right md:mr-0 md:pr-0 md:max-w-[295px] lg:max-w-sm  lg:text-16px lg:leading-22"
              >
                Curated toys for ages 0-24 months with sensory play and motor
                skills in mind. Swap for the next box when playtime is over.
              </Pgraph>
            </div>
            <div className="mt-4 text-center md:text-right">
              <Button
                primary={true}
                type="button"
                label="Explore Toy Boxes"
                onClick={() =>
                  router.push(
                    `${URLS.PRODUCTS.INDEX}/${process.env.NEXT_PUBLIC_TOY_BOX_CTA_REDIRECTION_PATH}`
                  )
                }
              ></Button>
            </div>
            <div className="hidden md:block absolute right-2 md:right-8 lg:right-2 -top-6 w-[70px] h-[70px]">
              <Image
                src={'/images/blue-block.svg'}
                alt="Blue block"
                width={70}
                height={70}
              />
            </div>
            <div className="hidden md:block absolute left-6 -bottom-2 w-[40px] h-[40px]">
              <Image
                src={'/images/green-circle.svg'}
                alt="Green Circle"
                width={40}
                height={40}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export { ToyBoxMolecule }
