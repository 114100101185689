import type { GetStaticProps, NextPage } from 'next'
import {
  PageContainer,
  Footer,
  ValuePropMolecule,
  HLWMolecule,
  FeaturedInMolecule,
  HomePageHero,
  TopProductsMolecule,
  NewArrivalsMolecule,
  BlogMolecule,
  LoopingInRealLifeMolecule,
  LetsMakeSomeSpaceMolecule,
  ShoppableImage,
  HomePageGiftcards,
  HomePageFeatureCarrousell,
  ByCategoryMolecule,
} from '../components'
import React, { useEffect, useRef } from 'react'
import { useAuthStore } from '../store'
import { trackEvent } from '../config/analytics'
import { AUTH0 } from '../config/common.enums'
import router from 'next/router'
import { ToyBoxMolecule } from '../components/ToyBoxMolecule'
import { LOOP_VALUE_PROPS, URLS } from '../config'
import { IProductFilters, IProductSummary } from '../interfaces'
import { PrismicService, ProductsApi, WebConfService } from '../services'
import {
  BlogPostDocument,
  PromoBannerDocument,
} from '../.slicemachine/prismicio'
import { Query } from '@prismicio/types'
import { INavigation } from '../interfaces/navigation.interfaces'

interface HomePageProps {
  filters: IProductFilters
  newArrivals: IProductSummary[]
  topProducts: IProductSummary[]
  //posts: Query<BlogPostDocument<string>>
  navigation: INavigation
  //promoBanner: PromoBannerDocument
}

export const getStaticProps: GetStaticProps<HomePageProps> = async ({
  previewData,
}) => {
  const navigationJson = await WebConfService.getNavigationStructure()
  if (!navigationJson) throw new Error('Error fetching Navigation Structure')

  const filtersRes = await ProductsApi.fetchProductFilters({
    homepage: true,
    showAll: false,
  })
  if (!filtersRes) throw new Error('Error fetching filters in home page')
  const filters = filtersRes?.filters

  const newArrivalsRes = await ProductsApi.fetchProducts({
    pageParam: 1,
    itemsPerPage: 10,
    filterOption: {
      categories: [filters.newArivalsCategoryId],
    },
  })

  if (!newArrivalsRes)
    throw new Error('Error fetching new arrivals in home page')
  const newArrivals = newArrivalsRes.products.slice(
    0,
    parseInt(process.env.NEXT_PUBLIC_NO_OF_PRODUCTS_IN_CAROUSEL) || 9
  )

  const topProductsRes = await ProductsApi.fetchProducts({
    pageParam: 1,
    itemsPerPage: 10,
    filterOption: {
      categories: [filters.topProductsCategoryId],
    },
  })

  const postsUids = [
    'best-baby-carriers',
    'best-sleep-products',
    'top-10-toddler-toys',
  ]

  // const prismicClient = PrismicService.createClient({ previewData })
  // const posts = await prismicClient.getByUIDs('blog_post', postsUids)

  if (!topProductsRes)
    throw new Error('Error fetching new arrivals in home page')
  const topProducts = topProductsRes.products.slice(
    0,
    parseInt(process.env.NEXT_PUBLIC_NO_OF_PRODUCTS_IN_CAROUSEL) || 9
  )

  // const promoBanner = await prismicClient.getSingle('promo_banner')

  // if (!promoBanner) {
  //   throw new Error('Error fetching promo banner in home page')
  // }

  return {
    props: {
      filters,
      newArrivals,
      topProducts,
      //  posts,
      navigation: navigationJson,
      //promoBanner,
    },
    revalidate: 300,
  }
}
const Home: NextPage<HomePageProps> = ({
  filters,
  newArrivals,
  topProducts,
  // posts,
  navigation,
  //promoBanner,
}) => {
  const { user } = useAuthStore()
  const pageViewed = useRef(false)

  useEffect(() => {
    if (!pageViewed.current) {
      pageViewed.current = true
      trackEvent('Page Viewed', {
        loopCustomerId: user?.customer?.loopCustomerId,
        page: 'Home Page',
        url: window?.location?.href,
      })
    }

    const search = window.location.search
    const params = new URLSearchParams(search)
    const successParams = params.get('success') || ''
    const messageParams = params.get('message') || ''
    if (
      successParams === 'false' &&
      messageParams === AUTH0.alreadyUsedPasswordLink
    ) {
      router.push('/login/reset-password')
    }
  }, [user?.customer?.loopCustomerId])

  return (
    <PageContainer
      navigation={navigation}
      seoAttributes={{
        title: 'Loop | Rent top-rated baby gear by the month',
        description:
          'A simpler way to shop for your baby: rent curated baby gear by the month.',
      }}
      //  promoBanner={promoBanner}
    >
      <div className="flex flex-col justify-center">
        <main className="">
          <HomePageHero />

          <HomePageFeatureCarrousell />

          <ByCategoryMolecule variant="homepage" ssrFilters={filters} />

          <FeaturedInMolecule />

          <HLWMolecule />

          <LoopingInRealLifeMolecule filters={filters} />

          {/* <HomePageGiftcards /> */}

          <LetsMakeSomeSpaceMolecule />

          {/* <CheckYourArea variant="grey" cta="Check your zip code" /> */}

          <TopProductsMolecule
            variant="homepage"
            title="Top Rentals"
            subTitle="A round up of our most popular Loops."
            products={topProducts}
            viewProductsUrl={`${URLS.PRODUCTS.INDEX}?categories=${filters.topProductsCategoryId}`}
          />

          <ToyBoxMolecule />

          {/* <BlogMolecule posts={posts} /> */}

          <NewArrivalsMolecule
            newArrivals={newArrivals}
            newArrivalsCategoryId={filters.newArivalsCategoryId}
          />

          <ShoppableImage />

          <ValuePropMolecule
            title="Why parents love Loop"
            propsList={LOOP_VALUE_PROPS}
          />

          {/* <SocialProofMolecule /> */}
        </main>
        <Footer></Footer>
      </div>
    </PageContainer>
  )
}

export default Home
